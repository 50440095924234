<template>
  <div class="animated fadeIn">
    <h1>Benutzer</h1>
    <div class="row align-items-center float-right col-2">
        <router-link style="margin-bottom: 10px" :to="'/user/editUser/-1'" tag="button" class="btn margin-bottom btn-primary btn-block">Neuen Benutzer anlegen</router-link>
    </div>
    <div id="users">
      <v-server-table ref="table" :columns="columns" :options="options">
        <template slot="edit" slot-scope="props">
          <router-link :to="'/user/editUser/'+props.row.username">
            <a class="fa fa-edit fa-lg"></a>
          </router-link>
          &nbsp;
          <a class="fa fa-trash fa-lg text-primary" @click="warningModal = true, selectedUser = props.row.username"></a>
        </template>
      </v-server-table>
    </div>


    <!-- Modal Component -->
    <b-modal title="Benutzer löschen?" class="modal-warning" v-model="warningModal" @ok="deleteUser()" ok-variant="warning">
      Wollen Sie den Benutzer wirklich löschen?
    </b-modal>

  </div>
</template>

<script>
import Vue from 'vue'
import {ServerTable} from 'vue-tables-2'

Vue.use(ServerTable)

const axios = require('axios')

export default {
     name: 'users',
     data () {
       return {
         warningModal: false,
         selectedUser: 0,
         nrOfEntries: 0,
         columns: ['username', 'firstname', 'lastname', 'edit'],
         options: {
              requestFunction: function(data, store) {
                  return axios.get(this.$store.state.baseUrl + 'user', { headers: { 'X-AUTH-TOKEN': this.$store.state.authObj.token } }).then((response)=> {
                    this.nrOfEntries = response.data.length
                    let pagedResult = {
                      data: [],
                    }
                    for (let i = ((data.page-1) * data.limit); i < (data.page * data.limit); i++){
                      if(i < response.data.length) {
                        pagedResult.data.push(response.data[i])
                      }
                    }
                    return pagedResult
                  }).catch(function (e){
                    if(e.response.status == 403){
                        this.$store.commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        this.dispatch('error', e);
                    }
                    return {
                        data: [],
                        count: 0,
                    }
                  }.bind(this))
              },
             responseAdapter({data}) {
                  return {
                      data,
                      count: this.nrOfEntries
                  }
             },
             headings: {
                  edit: ''
                 //enabled: 'Aktiviert',
                 //id: '',
             },
             //filterByColumn: true,
             filterable: false,
             sortable: [],
             sortIcon: {
                 base: 'fa',
                 up: 'fa-sort-up',
                 down: 'fa-sort-down',
                 is: 'fa-sort'
             },
             texts: {
                 //count: 'Showing {from} to {to} of {count} records|{count} records|One record - Test',
                 count: '',
                 filterPlaceholder: 'Search query',
             },
             pagination: {
                 nav: 'scroll'
             },
         }
       }
     },
     methods: {
         deleteUser() {
             axios.delete(this.$store.state.baseUrl + 'user/' + this.selectedUser, { headers: { 'X-AUTH-TOKEN': this.$store.state.authObj.token } })
                 .then((response)=>{ this.$refs.table.refresh()}).catch(error => {
                 if(error.response.status == 403){
                     this.$store.commit('setAuthObj', { authObj: null }, { root: true })
                 }
             });
         },
     },

}
</script>
